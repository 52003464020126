/* import __COLOCATED_TEMPLATE__ from './room.hbs'; */
import { action } from '@ember/object';
import { alias, or, reads } from 'macro-decorators';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

export default class PagesRoom extends Component {
  @service('twilio/video-stream') twilioVideoStream;
  @service('twilio/room') twilioRoom;
  @service('chime.meeting-manager') meetingManager;
  @service chat;
  @service mediaDevices;
  @service session;
  @service router;
  @service floatingUiElements;
  @service appointment;
  @service uiElements;
  @service call;

  @tracked sidebarElement = null;

  @reads('twilioRoom.isVideoPublished') isVideoPublished;
  @reads('twilioRoom.isAudioPublished') isAudioPublished;
  @reads('twilioRoom.localParticipant.networkLevel') localNetworkLevel;
  @reads('twilioRoom.connectionError') connectionError;
  @reads('twilioVideoStream.hasMainView') twilioHasMainView;
  @reads('chat.isChatShown') isChatShown;
  @reads('chat.isNotificationShown') isChatNotificationShown;
  @reads('floatingUiElements.hideControlsTask') hideControlsTask;
  @reads('floatingUiElements.eventNotificationShown') eventNotificationShown;
  @reads('floatingUiElements.controlsShown') controlsShown;
  @reads('floatingUiElements.joinRequestNotificationShown') joinRequestNotificationShown;
  @reads('floatingUiElements.RecordingNotificationHidden') RecordingNotificationHidden;
  @reads('session.isHost') isHost;
  @reads('session.inWaitingRoom') inWaitingRoom;
  @reads('session.roomModel.featureThClinicianAuth') featureThClinicianAuth;
  @reads('session.roomModel.featureThChime') featureThChime;
  @reads('call.isConnected') isConnected;
  @alias('twilioVideoStream.gridView') twilioGridView;
  @or('isVideoPublished', 'isAudioPublished') mediaEnabled;

  joinCallTask = task(async () => {
    if (this.featureThChime) {
      return this.appointment.join();
    }

    await this.mediaDevices.takeDevicesControl();
    let { roomModel } = this.twilioRoom;

    this.twilioRoom.setConnectionError(null);
    if (this.session.isHost || !roomModel.featureThClinicianAuth) {
      await this.twilioRoom.joinRoom();
    } else {
      await this.twilioRoom.joinTestRoom();
    }

    if (this.connectionError) {
      this.router.transitionTo('appointment');
    }
  });

  get isWaiting() {
    return this.featureThClinicianAuth && !this.isHost && !this.isConnected;
  }

  get hasControlsDisabled() {
    return this.joinCallTask.isRunning || (this.featureThChime && !this.meetingManager.audioVideo);
  }

  @action
  activateView(event) {
    if (event.type === 'focusin' && isEmberTesting()) return;

    let attacher = document.querySelector('.ember-attacher');
    if (attacher && attacher.contains(event.target) && event.type === 'focusin') {
      this.floatingUiElements.hideControlsTask.cancelAll();
      return;
    }

    this.floatingUiElements.setControlsVisibility(true);
    this.floatingUiElements.hideControlsTask.perform();
  }

  @action
  onSidebarPanelInsert(element) {
    this.sidebarElement = element;
  }
}
