/* import __COLOCATED_TEMPLATE__ from './box.hbs'; */
import { KEY_CODES } from 'frontend/constants';
import { action } from '@ember/object';
import { empty, or, reads } from 'macro-decorators';
import { modifier } from 'ember-modifier';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { waitForRender } from 'ember-simplepractice/utils/waiters';
import Component from '@glimmer/component';
import styles from './box.module.scss';

export default class CallChatBox extends Component {
  @service chat;
  @service appointmentSettings;
  @service persistentProperties;
  @service session;
  @service mixpanel;

  @tracked tooltipElem = null;
  @tracked isSending = false;
  @tracked messageText = '';

  styles = styles;

  @reads('session.roomModel.featureThHostControls') featureThHostControls;
  @reads('session.roomModel.featureThClinicianAuth') featureThClinicianAuth;
  @reads('session.isHost') isHost;
  @reads('appointmentSettings.chatEnabled') chatEnabled;
  @reads('chat.messages') messages;
  @empty('trimmedText') isInputEmpty;
  @or('isInputEmpty', 'isSending', 'isChatDisabled') isButtonDisabled;
  @reads('persistentProperties.chatBoxTooltipHidden') chatBoxTooltipHidden;

  get trimmedText() {
    return this.messageText.trim();
  }

  get isChatDisabled() {
    if (!this.featureThClinicianAuth) return false;
    return this.featureThHostControls && !this.isHost && !this.chatEnabled;
  }

  get showHostChatDisabledDisclaimer() {
    return this.featureThHostControls && this.isHost && !this.chatEnabled;
  }

  @action
  registerTooltip(val) {
    this.tooltipElem = val;
  }

  @action
  async sendMessage() {
    if (this.isChatDisabled) return;

    let lineSkippedCount = this.trimmedText.split('\n').length - 1;

    if (!this.chatBoxTooltipHidden) {
      this.persistentProperties.setProp('chatBoxTooltipHidden', true);
    }

    this.isSending = true;
    try {
      // todo: add error handling
      await this.chat.sendMessage(this.trimmedText);
      this.messageText = '';
    } finally {
      this.isSending = false;
    }
    this.mixpanel.track('chat sent', { 'line_skipped': lineSkippedCount });
  }

  @action
  handleKeyPress(evt) {
    if (evt.keyCode !== KEY_CODES.enter || evt.shiftKey) return;

    evt.preventDefault();

    if (this.isButtonDisabled) return;

    this.sendMessage();
  }

  @action
  handleKeyUp() {
    this.tooltipElem?.update();
  }

  @action
  closeChat() {
    this.chat.toggleChat();
  }

  scrollToMessage = modifier(async () => {
    await waitForRender();

    let message = this.chat.firstUnseenMessage || this.messages.lastObject;

    if (message) {
      document.getElementById(message.uuid).scrollIntoView();
    }

    if (this.chat.firstUnseenMessage) {
      this.chat.resetUnseenMessage();
    }
  });
}
