import * as dialogs from 'frontend/utils/dialogs';
import { GLOBAL_EVENT, GLOBAL_EVENT_TYPES, SHAREABLE_ENTITIES } from 'frontend/constants';
import { action } from '@ember/object';
import { and, equal, reads } from 'macro-decorators';
import { dependentKeyCompat } from '@ember/object/compat';
import { tracked } from '@glimmer/tracking';
import Service, { service } from '@ember/service';
import moment from 'moment-timezone';

export default class ChimeContentSharingService extends Service {
  @service('chime.meeting-manager') meetingManager;
  @service('chime.data-messaging') dataMessaging;
  @service('chime.roster') roster;
  @service('chime.sdk') sdkService;
  @service mediaDevices;
  @service errorHandling;
  @service uiElements;
  @service whiteboard;
  @service mixpanel;

  @tracked screenSharingTileId;
  @tracked shareAttendeeId;
  @tracked sharedEntity;

  startSharingTime;
  nativeScreenMediaStream;

  @reads('meetingManager.localAttendeeId') localAttendeeId;
  @reads('sdkService.sdk') chimeSdk;
  @equal('sharedEntity', SHAREABLE_ENTITIES.whiteboard) isWhiteboardShared;
  @equal('sharedEntity', SHAREABLE_ENTITIES.screen) isScreenShared;
  @and('isSharing', 'isWhiteboardShared') isSharingWhiteboard;
  @and('isSharing', 'isScreenShared') isSharingScreen;

  setup() {
    this.audioVideoObserver = { videoTileDidUpdate: this.videoTileDidUpdate };
    this.contentShareObserver = { contentShareDidStop: () => this.#resetSharingProperties() };
    this.meetingManager.audioVideo.addObserver(this.audioVideoObserver);
    this.meetingManager.audioVideo.addContentShareObserver(this.contentShareObserver);
    this.#subscribeToDataMessaging();
  }

  @dependentKeyCompat
  get isSharing() {
    return !!this.sharedEntity && this.shareAttendeeId === this.localAttendeeId;
  }

  stopSharing(options) {
    if (this.isSharing) {
      this.isSharingScreen ? this.#stopSharingScreen() : this.#stopSharingWhiteboard(options);
    } else {
      this.#resetSharingProperties();
    }
  }

  reset() {
    this.stopSharing({ showPrompt: false });
    this.meetingManager.audioVideo?.removeObserver(this.audioVideoObserver);
    this.meetingManager.audioVideo?.removeContentShareObserver(this.contentShareObserver);
  }

  #resetSharingProperties() {
    this.sharedEntity = null;
    this.shareAttendeeId = null;
    this.startSharingTime = null;
    this.screenSharingTileId = null;
    this.nativeScreenMediaStream = null;
    this.whiteboard.setInitialData({ state: null, isLocked: true });
  }

  toggleScreenSharing() {
    this.isSharingScreen ? this.#stopSharingScreen() : this.#startSharingScreen();
  }

  async #startSharingScreen() {
    try {
      let nativeScreenMediaStream = await this.mediaDevices.getDisplayMedia();
      if (!nativeScreenMediaStream) return;
      await this.meetingManager.audioVideo.startContentShare(nativeScreenMediaStream);
      this.sharedEntity = SHAREABLE_ENTITIES.screen;
      this.shareAttendeeId = this.localAttendeeId;
      this.startSharingTime = moment();
      this.nativeScreenMediaStream = nativeScreenMediaStream;
      this.uiElements.handleSharing();
    } catch (error) {
      switch (error.message) {
        case 'Permission denied':
          break;
        case 'Permission denied by system':
          this.errorHandling.systemPermissionsError();
          break;
        default:
          this.errorHandling.notifyError(error);
      }
    }
  }

  #handleRemoteScreenShareStart({ shareAttendeeId, tileState }) {
    this.uiElements.handleSharing();
    this.sharedEntity = SHAREABLE_ENTITIES.screen;
    this.shareAttendeeId = shareAttendeeId;
    tileState.boundVideoStream.getTracks().forEach(track => {
      track.onended = () => this.#resetSharingProperties();
    });
  }

  #stopSharingScreen() {
    this.#resetSharingProperties();
    this.meetingManager.audioVideo.stopContentShare();
  }

  bindScreenSharingTile(element) {
    if (!this.screenSharingTileId) return;
    this.meetingManager.audioVideo.bindVideoElement(this.screenSharingTileId, element);
  }

  @action
  videoTileDidUpdate(tileState) {
    if (
      tileState.boundAttendeeId &&
      tileState.boundVideoStream &&
      tileState.isContent &&
      tileState.tileId
    ) {
      this.screenSharingTileId = tileState.tileId;

      let { DefaultModality } = this.chimeSdk;
      let shareAttendeeId = new DefaultModality(tileState.boundAttendeeId).base();

      if (shareAttendeeId !== this.localAttendeeId && shareAttendeeId !== this.shareAttendeeId) {
        this.#handleRemoteScreenShareStart({ shareAttendeeId, tileState });
      }
    }
  }

  toggleWhiteboardSharing() {
    this.isSharingWhiteboard ? this.#stopSharingWhiteboard() : this.#startSharingWhiteboard();
  }

  shareVideoStream(stream) {
    return this.meetingManager.audioVideo.startContentShare(stream);
  }

  #startSharingWhiteboard() {
    if (this.shareAttendeeId) {
      dialogs.cannotStartSharing();
      return;
    }

    this.uiElements.handleSharing();
    this.sharedEntity = SHAREABLE_ENTITIES.whiteboard;
    this.shareAttendeeId = this.localAttendeeId;
    this.startSharingTime = moment();
    this.dataMessaging.sendMessage(
      { type: GLOBAL_EVENT_TYPES.startWhiteboard, isWhiteboardLocked: this.whiteboard.isLocked },
      GLOBAL_EVENT
    );
  }

  async #handleRemoteWhiteboardStart({ message, senderAttendeeId, timestamp }) {
    if (this.isSharing) {
      if (!moment(timestamp).isBefore(this.startSharingTime)) return;

      this.stopSharing({ showPrompt: false });
      dialogs.cannotStartSharing();
    }

    this.sharedEntity = SHAREABLE_ENTITIES.whiteboard;
    this.shareAttendeeId = senderAttendeeId;
    this.whiteboard.setInitialData({
      state: message.fileData?.fileBase64,
      isLocked: message.isWhiteboardLocked,
    });
    this.uiElements.handleSharing();
  }

  async #stopSharingWhiteboard({ showPrompt } = { showPrompt: true }) {
    if (showPrompt) {
      let { dismiss } = await dialogs.ensureStopWhiteboardSharing();
      if (dismiss) return;
    }

    this.dataMessaging.sendMessage({ type: GLOBAL_EVENT_TYPES.stopWhiteboard }, GLOBAL_EVENT);
    this.meetingManager.audioVideo.stopContentShare();
    this.whiteboard.stopVideoStream();
    this.#resetSharingProperties();

    this.mixpanel.track('whiteboard stopped');
  }

  toggleWhiteboardLocked() {
    let isWhiteboardLocked = this.whiteboard.isLocked;

    if (this.isSharing) {
      this.dataMessaging.sendMessage(
        {
          type: isWhiteboardLocked
            ? GLOBAL_EVENT_TYPES.unlockWhiteboard
            : GLOBAL_EVENT_TYPES.lockWhiteboard,
        },
        GLOBAL_EVENT
      );
    }
    this.whiteboard.setInitialData({ isLocked: !isWhiteboardLocked });
  }

  #subscribeToDataMessaging() {
    this.dataMessaging.addMessageHandler(GLOBAL_EVENT, messageProperties => {
      switch (messageProperties.message.type) {
        case GLOBAL_EVENT_TYPES.startWhiteboard:
          return this.#handleRemoteWhiteboardStart(messageProperties);
        case GLOBAL_EVENT_TYPES.stopWhiteboard:
          return this.#resetSharingProperties();
        case GLOBAL_EVENT_TYPES.lockWhiteboard:
        case GLOBAL_EVENT_TYPES.unlockWhiteboard:
          return this.toggleWhiteboardLocked();
      }
    });
  }
}
