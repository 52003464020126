export const dateFormat = 'MM/DD/YYYY';
export const simpleDateFormat = 'M/D/YY';
export const simpleDateFormatFullYear = 'M/D/YYYY';
export const dateTimeFormat = 'l h:mm a';
export const isoDateFormat = 'YYYY-MM-DD';
export const monthYearFormat = 'MMMM YYYY';
export const humanDateFormat = 'MMM D, YYYY';
export const monthDayOfMonthFormat = 'MMMM D';
export const shortMonthDayOfMonthFormat = 'MMM D';
export const hourMinutesFormat = 'HH:mm';
export const humanDateFullMonthFormat = `${monthDayOfMonthFormat}, YYYY`;
export const humanDateFullMonthOrdinalFormat = 'MMMM Do, YYYY';
export const humanDateOrdinalFormat = 'MMM Do, YYYY';
export const humanDateTimeFormat = 'MMM D, YYYY h:mm A';
export const isoDateTimeFormat = `${isoDateFormat} ${hourMinutesFormat}`;
export const humanFullDateFormat = `M${humanDateFormat}`;
export const humanFullDateOrdinalFormat = `M${humanDateOrdinalFormat}`;
export const timeFormat = 'h:mm A';
export const timeLowercaseFormat = 'h:mma';
export const shortTimeFormat = 'h a';
export const cardExpiryFormat = 'MM / YY';
export const weekDayFormat = 'ddd';
export const fullDateFormat = 'dddd, MMMM DD';
export const dayOfMonthFormat = 'D';
export const dayOfMonthOrdinalFormat = 'Do';
export const humanWeekDayFormat = `d${weekDayFormat}`;
export const humanWeekDayDateFormat = `${humanWeekDayFormat}, ${dateFormat}`;
export const humanWeekDayDateOrdinalFormatFormat = `${humanWeekDayFormat}, ${humanFullDateOrdinalFormat}`;
export const weekDayHumanDateFormat = `${weekDayFormat}, ${humanDateFormat}`;
export const humanWeekDayAndDateFormat = `${humanWeekDayFormat}, ${humanDateFormat}`;

export const calendarFormat = {
  resourceTimeGridDay: {
    dateFormat: weekDayHumanDateFormat,
    humanDateFormat: `${humanWeekDayFormat}, ${humanFullDateFormat}`,
  },
  timeGridWeek: {
    weekDayFormat: `${weekDayFormat} D`,
    monthYearFormat: 'MMM YYYY',
  },
  dayGridMonth: {
    weekDayFormat,
    monthYearFormat,
  },
};

export default {
  dateFormat,
  simpleDateFormat,
  dateTimeFormat,
  isoDateFormat,
  monthYearFormat,
  humanDateFormat,
  humanDateTimeFormat,
  isoDateTimeFormat,
  humanFullDateFormat,
  timeFormat,
  hourMinutesFormat,
  timeLowercaseFormat,
  shortTimeFormat,
  cardExpiryFormat,
  humanWeekDayFormat,
  calendarFormat,
  humanWeekDayDateFormat,
  humanWeekDayAndDateFormat,
  weekDayHumanDateFormat,
  dayOfMonthFormat,
  monthDayOfMonthFormat,
  shortMonthDayOfMonthFormat,
};
