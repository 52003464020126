import { ROOM_REFERRER } from 'frontend/constants';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitForRender } from 'ember-simplepractice/utils/waiters';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';

@classic
export default class AppointmentRoomRoute extends Route {
  @service persistentProperties;
  @service session;
  @service router;

  queryParams = { referrer: { replace: true } };

  @reads('persistentProperties.userName') userName;

  beforeModel(transition) {
    super.beforeModel(transition);
    if (this.userName) return;
    this.router.transitionTo('appointment');
  }

  model(params, transition) {
    this.session.setInWaitingRoom(!this.isHost);

    return super.model(params, transition);
  }

  afterModel(_, transition) {
    if (!this.session.isAuthenticated || transition.to?.queryParams?.referrer !== ROOM_REFERRER)
      return;

    this.session.showLoginNotification();
    this.clearReferrerParamTask.perform();
  }

  willTransition(transition) {
    transition.router.refresh();
  }

  clearReferrerParamTask = task(async () => {
    await waitForRender();
    this.router.transitionTo('appointment.room', { queryParams: { referrer: null } });
  });
}
