import { classNames } from '@ember-decorators/component';
import Super from 'ember-simplepractice/components/shared/browser-update-banner';
import styles from './browser-update-banner.module.scss';

@classNames(styles.component)
export default class SharedBrowserUpdateBanner extends Super {
  _isInBetween({ n: name, v: version }) {
    let requiredVersion = this.environment.browserUpdateConfig.incompatible.required?.[name];
    let compatibleVersionDifference =
      this.environment.browserUpdateConfig.compatible.required?.[name];
    let availableVersion = parseFloat(this.browserInfo.available?.[name]);

    if (compatibleVersionDifference > 0 || !availableVersion)
      return super._isInBetween(...arguments);

    let parsedVersion = parseFloat(version);
    let latestCompatibleVersion = availableVersion + compatibleVersionDifference;

    return parsedVersion >= requiredVersion && parsedVersion < latestCompatibleVersion;
  }
}
